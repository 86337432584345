import React from 'react';
import { type RouteObject } from "react-router";
import { RedirectCallback } from "../pages/RedirectCallback.js";
import { ACCEPT_INVITE, REDIRECT_CALLBACK, ROOM } from "../paths.js";
import { lazyNamedComponent } from '../utils/lazyNamedComponent.js';
import { Authenticated } from "./auth/Authenticated.js";

const AcceptInvitePage = lazyNamedComponent(() => import('../pages/AcceptInvitePage.js'), 'AcceptInvitePage');
const RoomPage = lazyNamedComponent(() => import('../pages/RoomPage.js'), 'RoomPage');
const RoomListPage = lazyNamedComponent(() => import('../pages/RoomListPage.js'), 'RoomListPage');

export const ROUTES: RouteObject[] = [
    {
        element: <RedirectCallback />,
        path: REDIRECT_CALLBACK,
    },
    {
        element: <Authenticated><RoomPage /></Authenticated>,
        path: ROOM,
    },
    {
        element: <Authenticated><RoomListPage /></Authenticated>,
        path: '/',
    },
    {
        element: <Authenticated><AcceptInvitePage /></Authenticated>,
        path: ACCEPT_INVITE,
    },
]
