import React, { type ReactNode } from 'react';
import { type RouteObject, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

/**
 * Stand-in for BrowserRouter that takes
 */
export function ObjectBrowserRouter(
    {
        children,
        routes,
    }: {
        children: ReactNode;
        routes: RouteObject[];
    },
): JSX.Element {
    return <RouterProvider router={createBrowserRouter([
        {
            path: '/',
            element: children,
            children: routes,
        },
    ])}/>;
}
