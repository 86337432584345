import { useEffect } from 'react';
import { useApiClient } from '../contexts/WebSocket/index.js';

const noop = { type: 'NO_OP' } as const;
const TIMER_INTERVAL_MS = 30_000;

/**
 * NGINX auto-closes the socket after 60 seconds, so send a "no-op" message every 30 seconds to keep it open.
 */
export function useKeepConnectionAlive() {
    const client = useApiClient();
    useEffect(() => {
        if (!client) return;

        const intervalHandle = setInterval(() => client.call(noop), TIMER_INTERVAL_MS);
        return () => clearInterval(intervalHandle);
    }, [client]);
}
