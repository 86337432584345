import { assertAllCasesCovered } from '@karlrwjohnson/tabletop-common/src/utils/assertAllCasesCovered.js';
import { getErrorFields } from '@karlrwjohnson/tabletop-common/src/utils/getErrorFields.js';
import { type ReactNode, useMemo } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import {
    isLoggedIn,
    LOADING_SESSION_FROM_REDIRECT,
    LOADING_SESSION_FROM_STORAGE,
    NOT_LOGGED_IN,
    useAuthState
} from '../contexts/AuthProvider.js';
import { apiClientContext, useWebSocketState } from '../contexts/WebSocket.js';
import { useLoginFunction } from '../pages/RedirectCallback.js';
import { useContextWithProviderAssertion } from '../utils/createContextWithProviderAssertion.js';

export function ClientLoadingOverlayComponent(
    {
        error,
        header,
        loading = false,
        message,
        show = true,
        ...props
    }: {
        error?: unknown;
        header?: ReactNode;
        loading?: boolean;
        message: ReactNode;
        show?: boolean;
    } & (
        // These properties must be specified together, or not at all
        {
            actionLabel?: never;
            onAction?: never;
        } | {
            actionLabel: string;
            onAction: () => void;
        }
    )
): JSX.Element {
    const errorFields = useMemo(() => error ? getErrorFields(error) : null, [error]);
    return (
        <Modal show={show}>
            {header && <Modal.Header>{header}</Modal.Header>}
            <Modal.Body>
                <>
                    <p>{message}</p>
                    {errorFields && <details><>
                        <summary className="text-muted" style={{ fontSize: 'small', fontStyle: 'italic' }}>
                            Details
                        </summary>
                        <p><strong>{errorFields.name}</strong>: {errorFields.message}</p>
                        {errorFields.stack && <>
                            <p>Stack Trace:</p>
                            <pre style={{ overflow: 'auto', fontSize: 'small', maxHeight: '20em' }}>
                                {errorFields.stack}
                            </pre>
                        </>}
                    </></details>}
                    {loading && <center><Spinner animation="border" variant="primary" /></center>}
                </>
            </Modal.Body>
            {props.onAction &&
            <Modal.Footer className="justify-content-center">
                <Button variant="primary" onClick={props.onAction}>
                    {props.actionLabel}
                </Button>
            </Modal.Footer>
            }
        </Modal>
    );
}

export function ClientLoadingOverlay(
    {
        children,
    }: {
        children: ReactNode;
    }
) {
    const authState = useAuthState();
    const socketState = useWebSocketState();
    const clientState = useContextWithProviderAssertion(apiClientContext);

    const doLogIn = useLoginFunction();

    let overlay: ReactNode = <ClientLoadingOverlayComponent message="Connected!" show={false} />;
    if (!isLoggedIn(authState)) {
        switch (authState) {
            case NOT_LOGGED_IN:
                overlay = <ClientLoadingOverlayComponent
                    actionLabel="Login"
                    header={<h2 className="text-center flex-fill">Welcome to Kabletop</h2>}
                    message={<span style={{ display: 'block', textAlign: 'center' }}>Please log in to continue</span>}
                    onAction={doLogIn}
                />;
                break;
            case LOADING_SESSION_FROM_STORAGE:
                overlay = <ClientLoadingOverlayComponent loading message="Looking for saved credentials..." />;
                break;
            case LOADING_SESSION_FROM_REDIRECT:
                overlay = <ClientLoadingOverlayComponent loading message="Loading credentials..." />;
                break;
            default:
                assertAllCasesCovered(authState);
        }
    } else if (socketState.state !== 'CONNECTED') {
        switch (socketState.state) {
            case 'CONNECTING':
                overlay = <ClientLoadingOverlayComponent loading message="Connecting to server..." />;
                break;
            case 'ERROR':
                overlay = <ClientLoadingOverlayComponent
                    actionLabel="Reconnect"
                    error={socketState.error}
                    message="Unable to connect to server"
                    onAction={socketState.reconnect}
                />;
                break;
            case 'CLOSED':
                overlay = <ClientLoadingOverlayComponent
                    actionLabel="Reconnect"
                    message="Server connection lost"
                    onAction={socketState.reconnect}
                />;
                break;
            default:
                assertAllCasesCovered(socketState);
        }
    } else if (clientState.state !== 'CONNECTED') {
        switch (clientState.state) {
            case 'WAITING_FOR_SOCKET':
                // I don't think we can ever hit this state. The socketState will take precedence.
                overlay = <ClientLoadingOverlayComponent
                    loading
                    message="Waiting for server connection..."
                />;
                break;
            case 'CONNECTING':
                overlay = <ClientLoadingOverlayComponent
                    loading
                    message="Logging in..."
                />;
                break;
            case 'ERROR':
                overlay = <ClientLoadingOverlayComponent
                    actionLabel="Reconnect"
                    error={clientState.error}
                    message="Authentication error"
                    onAction={socketState.reconnect}
                />;
                break;
            case 'CONNECTION_LOST':
                // I think this bit's never going to get hit -- I think you can only hit this when socketState is also "closed"
                overlay = <ClientLoadingOverlayComponent
                    actionLabel="Reconnect"
                    message="Server connection lost"
                    onAction={socketState.reconnect}
                />;
                break;
            default:
                assertAllCasesCovered(clientState);
        }
    }

    return (
        <>
            {overlay}
            {children}
        </>
    )
}
