import { MembershipStatus } from "@karlrwjohnson/tabletop-common/src/model/index.js";
import { screamingSnakeToTitleCase } from "@karlrwjohnson/tabletop-common/src/utils/strings/index.js";
import { useEffect } from "react";
import { useToaster } from "../contexts/ToastContext.js";
import { useApiClient } from "../contexts/WebSocket.js";
import { store, useDispatch } from "../store.js";

export function useAccountEvents() {
    const client = useApiClient();
    const dispatch = useDispatch();
    const toaster = useToaster();

    useEffect(() => {
        const subscription = client?.topic({
            type: 'ACCOUNT',
        }).subscribe({
            next(evt) {
                switch (evt.type) {
                    case "ACCOUNT_JOINED":
                        toaster.info(`Joined room ${evt.roomName} as a ${screamingSnakeToTitleCase(evt.role)}`);
                        break;
                    case "ACCOUNT_CHANGED":
                        toaster.info(`Updated account`);
                        break;
                    case "ACCOUNT_MEMBERSHIP_UPDATED": {
                        const roomName = evt.changes.roomName ?? store.getState().rooms[evt.roomId]?.name ?? evt.roomId;
                        if (evt.changes.role) {
                            toaster.info(`You are now a ${screamingSnakeToTitleCase(evt.changes.role)} in ${roomName}`);
                        }
                        if (evt.changes.membershipStatus === MembershipStatus.BLOCKED) {
                            toaster.info(`You have been removed from ${roomName}`);
                        }
                        break;
                    }
                    case "ACCOUNT_MEMBERSHIP_REMOVED": {
                        const roomName = store.getState().rooms[evt.roomId]?.name ?? evt.roomId;
                        toaster.info(`You have left ${roomName}`);
                    }
                }

                dispatch(evt);
            },
            error: err => console.error(err),
        });

        return () => subscription?.unsubscribe();
    }, [client, dispatch, toaster]);
}
