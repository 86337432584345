import 'bootstrap/dist/css/bootstrap.css';
import { UserManager } from "oidc-client-ts";
import React, { Suspense } from 'react';
import { createRoot } from "react-dom/client";
import { Provider as ReactReduxProvider } from 'react-redux';
import { Outlet } from 'react-router';
import { ROUTES } from "./components/AppRoutes.js";
import { ClientLoadingOverlay } from './components/ClientLoadingOverlay.js';
import { useKeepConnectionAlive } from './components/KeepConnectionAlive.js';
import { OAUTH_CLIENT_ID, OAUTH_ISSUER, PUBLIC_PATH } from './constants.js';
import './index.module.css';
import { AuthProvider } from './contexts/AuthProvider.js';
import { ToastContext } from './contexts/ToastContext.js';
import { ApiClientContext } from './contexts/WebSocket/index.js';
import { useAccountEvents } from './io/useAccountEvents.js';
import { REDIRECT_CALLBACK } from "./paths.js";
import './utils/livereload.js';
import { store } from './store.js';
import { ObjectBrowserRouter } from './utils/ObjectBrowserRouter.js';
import { pyramidOf } from './utils/pyramidOf.js';
import { Use } from './utils/Use.js';

export const userManager = new UserManager({
    authority: OAUTH_ISSUER,
    client_id: OAUTH_CLIENT_ID,
    redirect_uri: window.location.origin + PUBLIC_PATH.replace(/\/+/, '') + REDIRECT_CALLBACK,
});

const div = document.createElement('div');
document.body.appendChild(div);

createRoot(div).render(
    pyramidOf(
        <>
            <Use hook={useAccountEvents} />
            <Use hook={useKeepConnectionAlive} />
            <Outlet />
        </>
    )
        .wrapWith(Suspense, { fallback: <div>Loading...</div> })
        .wrapWith(ClientLoadingOverlay, {})
        .wrapWith(ApiClientContext, {})
        .wrapWith(AuthProvider, { userManager })
        .wrapWith(ReactReduxProvider, { store })
        .wrapWith(ToastContext, {})
        .wrapWith(ObjectBrowserRouter, { routes: ROUTES })
        .build()
);
