import { type ComponentType, lazy } from 'react';

/**
 * Wrapper to use non-default-exported components with React.lazy()
 * @param moduleImportFn - Async callback function to return the module. The body of this ought to be an import statement.
 * @param exportName - Name of the exported component in the module
 */
export function lazyNamedComponent<Name extends string, ComponentImpl extends ComponentType<any>>(
    moduleImportFn: () => Promise<{ [name in Name]: ComponentImpl }>,
    exportName: Name,
) {
    // return lazy(() => moduleImportFn().then(module => ({ default: module[exportName] })));
    return lazy(() => {
        console.debug('start lazy-loading', exportName)
        return moduleImportFn().then(module => {
            console.debug('finish lazy-loading', exportName)
            return ({ default: module[exportName] });
        });
    });
}
